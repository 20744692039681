import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../api/client"

const apiURL = process.env.REACT_APP_RECORDING_API_URL

const initialState = {
  recordings: [],
  recordingFile: '',
  status: 'idle',
  error: null,
  lastkey: {},
  recordingParams: {},
}

const QueryParamaters = {
  DateFrom: '',
  DateTo: '',
  UserIds: [],
  ConversationId: '',
  Direction: '',
  QueueIds: [],
  WrapUpCodes: [],
}

export const fetchRecordings = createAsyncThunk(
  'recordings/fetchRecordings',
  async (paramaters) => {
    console.log('search', paramaters)

    const response = await client.post(apiURL, '/api/v1/query', paramaters)
    console.log('fetchRecordings', response.data)

    return response.data
  })

export const fetchRecordingFile = createAsyncThunk(
  'recordings/fetchRecordingFile',
  async (paramaters) => {
    //console.log('fetchRecordingFile init: ', paramaters)
    try {
      const response = await client.get(apiURL, '/api/v1/retrieve/?key=' + paramaters)
      console.log('fetchRecordingFile resp: ', paramaters, response.data)
      return response.data
    } catch (error) {
      console.log('recordings/fetchRecordingFile: ', error)
    }
  })

export const fetchMoreRecordings = createAsyncThunk(
  'recordings/fetchMoreRecordings',
  async (paramaters) => {
    try {
      const response = await client.post(apiURL, '/api/v1/query', paramaters)
      //console.log('fetchMoreRecordings', response.data)
      return response.data
    } catch (error) {
      console.log('recordings/fetchMoreRecordings', error)
    }

  })

const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    clearData(state, action) {
      state.recordings = [];
      state.recordingFile = '';
      state.recordingParams = {};
    },
    setRecordingParams(state, action) {
      state.recordingParams = action.payload;
      //console.log('setRecordingParams', state.recordingParams)
    },
    updateRecordingParamsLastKey(state, action) {
      state.recordingParams.LastEvaluatedKey = action.payload;
      //console.log('updateRecordingParamsLastKey', state.recordingParams)
    },
    resetRecordingFile(state, action) {
      state.recordingFile = '';
    }
  },
  extraReducers(builder) {
    builder
      //get recordings
      .addCase(fetchRecordings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchRecordings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.recordingParams.LastEvaluatedKey = action.payload.LastEvaluatedKey
        //set fetched recordings
        state.recordings = action.payload.CallsTable
      })
      .addCase(fetchRecordings.rejected, (state, action) => {
        state.status = 'failed'
        console.log('fetchRecordings.rejected error: ', action.error.message)
        state.error = action.error.message
      })

      //get more recordings using LastEvalutatedKey
      .addCase(fetchMoreRecordings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMoreRecordings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.recordingParams.LastEvaluatedKey = action.payload.LastEvaluatedKey
        //update fetched recordings
        state.recordings = state.recordings.concat(action.payload.CallsTable)
      })
      .addCase(fetchMoreRecordings.rejected, (state, action) => {
        state.status = 'failed'
        console.log('fetchMoreRecordings.rejected error: ', action.error.message)
        state.error = action.error.message
      })

      //get recording file and add to recording obj
      .addCase(fetchRecordingFile.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchRecordingFile.fulfilled, (state, action) => {
        state.status = 'succeeded'
        //set fetched recording file
        console.log('fetchRecordingFile.fulfilled', action.payload)
        state.recordingFile = action.payload
      })
      .addCase(fetchRecordingFile.rejected, (state, action) => {
        state.status = 'failed'
        console.log('fetchRecordingFile.rejected error: ', action.error.message)
        state.error = action.error.message
      })
  }
})

export const { clearData, setRecordingParams, updateRecordingParamsLastKey, resetRecordingFile } = recordingsSlice.actions

export const selectRecordings = (state) => state.recordings.recordings
export const selectRecordingFile = (state) => state.recordings.recordingFile
export const selectLastEvaluatedKey = (state) => state.recordings.lastkey

export default recordingsSlice.reducer