// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { Auth } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

const getIdToken = async () => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      console.log('idToken', idToken)
      return idToken;
    } catch (err) {
      console.log(err);
    }
};

export async function client(apiURL, endpoint, { body, ...customConfig } = {}) {

    const headers = { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await getIdToken()}`
     }
  
    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }
  
    if (body) {
      config.body = JSON.stringify(body)
    }
  
    let data
    try {
      const response = await window.fetch(`${apiURL}${endpoint}`, config)
      data = await response.json()
      if (response.ok) {
        // Return a result object similar to Axios
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data)
    }
  }
  
  client.get = function (apiURL, endpoint, customConfig = {}) {
    return client(apiURL, endpoint, { ...customConfig, method: 'GET' })
  }
  
  client.post = function (apiURL, endpoint, body, customConfig = {}) {
    console.log('request body', body)
    return client(apiURL, endpoint, { ...customConfig, body })
  }
  