import { configureStore } from '@reduxjs/toolkit'
import authenticationReducer from "./features/auth/authenticationSlice"
import filterReducer from "./features/filters/filterSlice"
import recordingsSlice from "./features/recordings/recordingsSlice"

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        filter: filterReducer,
        recordings: recordingsSlice,
    }
});
