import { RouterProvider } from 'react-router-dom';
import routes from "./routes/Router";
import { useTheme } from "./hooks/useTheme";
import Logo from "./layouts/Logo";

/**
 * Following along with the
 * tutorial https://ui.docs.amplify.aws/react/guides/auth-protected?
 * Update this import to match the path to your aws-exports.js file:
 * import aws_exports from "./aws-exports";
 */
//Authenticator
import { Amplify } from 'aws-amplify';
import { Authenticator, Button, Heading, Text, View } from '@aws-amplify/ui-react';

import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, fetchAuthSession, getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

import { useDispatch } from 'react-redux'
import { fetchAuthenticatedUser } from "./features/auth/authenticationSlice"

import { useState } from 'react';

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      /* identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID, */
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
          scopes: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
          ],
          // can wrap this in if statement - https://github.com/aws-amplify/amplify-cli/issues/2792
          // if (!isLocalhost) {
          redirectSignIn: [process.env.REACT_APP_AWS_OAUTH_REDIRECT_URI],
          redirectSignOut: [process.env.REACT_APP_AWS_OAUTH_REDIRECT_URI],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  }
});


export default function App() {

  const routing = routes();
  const theme = useTheme();

  const dispatch = useDispatch()

  const [myConfirmSignupShow, setMyConfirmSignUpShow] = useState(false)

  async function SSOLogin() {
    console.log("Starting Login")
    try {
      await signInWithRedirect({ provider: { custom: process.env.REACT_APP_AWS_IDP } })
    } catch (error) {
      console.error("SAML Federated Sign-In Error:", error);
    }
  }

  //https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced
  const components = {
    Header() {
      return (
        <div className="my-5">
          <Logo className="mt-5"></Logo>
        </div>
      );
    },

    SignIn: {
      Header() {
        return (
          <>
            <form data-amplify-form style={{ paddingTop: '0', height: '100px', width: 'auto' }}>
              <Button className="amplify-button amplify-field-group__control amplify-button--outline amplify-button--fullwidth"
                style={{ margin: '2rem 0' }}
                onClick={() => SSOLogin()} >
                Login with SSO
              </Button>
            </form>
            <div style={{ marginTop: '1rem' }}>
              <div className="amplify-flex federated-sign-in-container"
                style={{ flexDirection: 'column', padding: '0px 0px 1rem' }}>
                <hr aria-orientation="horizontal" className="amplify-divider amplify-divider--horizontal amplify-divider--small amplify-divider--label" data-size="small" data-label="or" />
              </div>
            </div>
          </>
        );
      },
    },
  }

  const userAuthenticated = () => {
    dispatch(
      fetchAuthenticatedUser()
    );
  }

  //https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/#listen-to-and-log-auth-events
  const listener = (data) => {
    switch (data?.payload?.event) {
      case 'signUp':
        setMyConfirmSignUpShow(true)
        console.log('user signed up');
        break;
      case 'confirmSignUp':
      case 'signIn':
        setMyConfirmSignUpShow(false)
        console.log('user confirmation successful');
        break;
      case 'cognitoHostedUI':
        console.log('Authenticated...');
        //console.log(token);
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        console.log('Error', data);
        break;
      default:
        //setMyConfirmSignUpShow(false)
        console.log('unknown event type: ', data?.payload?.event);
        break;
    }
  }

  Hub.listen('auth', listener)

  return (
    <div className={theme}>
      <Authenticator.Provider>
        <View>
          <Authenticator components={components} loginMechanisms={['email']} >
            {({ signOut, user }) => (
              <main>
                {/* {console.log('user', user)} */}
                {userAuthenticated()}
                {/* <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button> */}
                <RouterProvider router={routing} />
              </main>
            )}
          </Authenticator>
        </View>
      </Authenticator.Provider>
      {myConfirmSignupShow ? (
        <>
          <div data-amplify-authenticator style={{ 'marginTop': '0rem' }}>
            <div data-amplify-container>
              <div data-amplify-router>
                <div dir="ltr" data-orientation="horizontal">
                  <div data-amplify-router-content="">
                    <div>
                      <form data-amplify-form style={{ height: '480px', width: 'auto' }}>
                        {/* <div style={{ width: 'auto' }} className='container-fluid text-center my-3'>
                          <i className="bi bi-person-exclamation display-1 primary"></i>
                        </div> */}
                        <Heading level={4}>Pending Signup Confirmation</Heading>
                        <div style={{ height: '2rem' }}></div>
                        <Text level={2}>Please contact an administrator so we can verify your account and get you all setup.</Text>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (null)}
    </div>
  );
};
